.imprint__section {
    margin-top: 0rem !important;
    height: 100vh;
    padding-top: 1rem;
}

.imprint__container {
    text-align: left;
    height: 100%;
    position: relative;
}

a, h2, h3, h4, p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}